import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CanvasGrid from "../../components/canvas-grid"
import { Helmet } from "react-helmet"

import CookieConsentContext from "../../context/CookieContext"
import { CookiePopUpContent } from "../../components/cookiePopup"

const ArticlePage = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
      <SEO title={frontmatter.title} />

      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <CanvasGrid class="canvas-grid--light" theme="light" />
      <div className="container-fluid">
        <div className="blog-post col-12 col-lg-8 sp-mt-9 sp-mb-9">
          <h1 className="text--lg color--black sp-mb-7">{frontmatter.title}</h1>
          <div
            className="text--md color--black"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($language: String) {
    markdownRemark(
      frontmatter: { slug: { eq: "cookies-policy" }, lang: { eq: $language } }
    ) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`

export default ArticlePage
